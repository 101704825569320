<template>
<div>
  <v-progress-linear v-if="Loading"></v-progress-linear>
  <v-card v-if="contract">
<!--    <v-toolbar color="nyGreen" dark class="mb-0">-->
<!--      <v-toolbar-title class="nyGreen&#45;&#45;text text&#45;&#45;darken-4 poppins-semibold">-->
<!--        {{ contract.Title }}-->
<!--      </v-toolbar-title>-->
<!--      <v-spacer></v-spacer>-->
<!--      <v-toolbar-items>-->
<!--        <v-btn color="nyGreen darken-2" @click="$emit('input', false)">-->
<!--          <v-icon>fas fa-times-circle</v-icon>-->
<!--        </v-btn>-->
<!--      </v-toolbar-items>-->
<!--    </v-toolbar>-->
    <!--    <div class="my-3 text-center">-->
    <!--      <v-btn large color="nyGreen" @click="$emit('input', false)">{{$t("common.back")}}</v-btn>-->
    <!--    </div>-->
    <v-card-title>
      {{ contract.Title }}
    </v-card-title>
    <v-card-text class="pa-4 poppins-regular" v-html="contract.ContractText">
    </v-card-text>
<!--    <div class="my-3 text-center">-->
<!--      <v-btn large color="nyGreen" @click="$emit('input', false)">{{$t("common.back")}}</v-btn>-->
<!--    </div>-->
  </v-card>
</div>
</template>
<script>
import CrudClient from "@/services/CrudClient";
import { mapActions } from "vuex";

export default {
	name: "ContractViewer",
	props: {
		ContractTypeId: {
			type: Number,
			required: true
		},
		data: {
			contract: null,
			Loading: false,
		}
	},
	created() {
		this.PatientContractController = new CrudClient("Patients/Contracts");
	},
	async mounted() {
		this.contract = await this.PatientContractController.Get(this.ContractTypeId);
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),
	}
}
</script>

<style scoped lang="scss">

</style>
