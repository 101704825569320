<template>
	<v-card class="mb-4 elevation-5">
		<ConfirmTool ref="confirm"></ConfirmTool>
		<v-card-text class="ma-0 pa-0">
			<v-container fluid>
				<v-row dense>
					<v-col>
						<h6 class="text-h6">
							<v-icon>fas fa-calendar-alt</v-icon>
							<span> {{ booking.BookingTime | formatDateTime }}</span>
						</h6>
					</v-col>
					<v-col class="text-right">
						<nutriYouBookingTypeChip :StatusId="booking.BookingTypeId"></nutriYouBookingTypeChip>

					</v-col>
				</v-row>
				<v-row dense><v-col>
						<UserAvatarFullname :profile="booking.Nutritionist"></UserAvatarFullname>
					</v-col>
					<v-col class="text-right">
						<span class="text-h5">
							{{ booking.TotalPaymentAmount | formatCurrency }}</span>
						<br />
						<span class="font-weight-bold">
							{{ booking.Duration }} minuti</span>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col>
						<nutriYouOrderStatusChip :StatusId="booking.BookingStatusId"></nutriYouOrderStatusChip>
					</v-col>
					<v-col class="text-right">
						<span class="ml-2 font-weight-bold grey--text">
							Id: {{ booking.BookingId }} </span>
					</v-col>
				</v-row>
			</v-container>
		</v-card-text>
		<v-card-actions>

			<v-spacer></v-spacer>
			<v-btn v-if="booking.BookingStatusId === BookingStatusIdEnum.BOOKED && DefaultMeetingRoomUrl"
				class="text-capitalize" color="nyrose"
				:href="DefaultMeetingRoomUrl" target="_blank" dark>
				<v-icon left>fas fa-video</v-icon>
				Vai alla Videochiamata
			</v-btn>
			<StripeCheckoutButton v-if="booking.BookingStatusId === BookingStatusIdEnum.WAITINGFORPAYMENT"
				:booking="booking" color="primary" class="text-capitalize"
				@RequireBillingProfile="RequireBillingProfile"
        @RequirePrivacyContract="RequirePrivacyContract"
        ref="stripeButton"></StripeCheckoutButton>
			<AddEditBillingProfile v-model="showAddBillingProfile" :mode="1" @updated="OnBillingProfileUpdated"
				Header="Mancano i dati per la fattura">
			</AddEditBillingProfile>

      <PrivacyContractsDialog v-model="showPrivacyContracts"
              @contractsAccepted="OnPrivacyContractAccepted" />
		</v-card-actions>
	</v-card>
</template>
<script>
import { mapActions } from "vuex";
import CrudClient from "@/services/CrudClient/";
import ConfirmTool from "@/components/Shared/Common/Confirm";
import UserAvatarFullname from "@/components/Shared/UI/UserAvatarFullname";
import nutriYouOrderStatusChip from "@/components/NutriYou/nutriYouOrderStatusChip";
import nutriYouBookingTypeChip from "@/components/NutriYou/nutriYouBookingTypeChip";
import StripeCheckoutButton from "./StripeCheckoutButton";
import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";

import AddEditBillingProfile from "@/components/Account/AddEditBillingProfile";
import PrivacyContractsDialog from "@/views/Patients/components/PrivacyContractsDialog.vue";

export default {
	components: {
		PrivacyContractsDialog,
		UserAvatarFullname,
		nutriYouOrderStatusChip,
		nutriYouBookingTypeChip,
		ConfirmTool,
		StripeCheckoutButton,
		AddEditBillingProfile,
	},
	props: {
		booking: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			showModifyDialog: false,
			newDataTime: null,
			showAddBillingProfile: false,
			DefaultMeetingRoomUrl: null,
			showPrivacyContracts: false
		};
	},
	created() {
		this.BookingStatusIdEnum = BookingStatusIdEnum;
		this.BookingsService = new CrudClient("CurrentNutritionist/Bookings");
		this.NutritionistsService = new CrudClient("Nutritionists/Public");
	},
	async mounted() {
		if (this.booking.BookingStatusId === BookingStatusIdEnum.BOOKED) {
			const nutritionist = await this.NutritionistsService.Get(this.booking.NutritionistUserId);
			this.DefaultMeetingRoomUrl = nutritionist.DefaultMeetingRoomUrl;
		}
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		RequireBillingProfile() {
			this.showAddBillingProfile = true;
		},

		OnBillingProfileUpdated() {
			this.showAddBillingProfile = false;

			this.$refs.stripeButton.redirectToStripe();
		},

		RequirePrivacyContract() {
			this.showPrivacyContracts = true;
		},

		OnPrivacyContractAccepted() {
			this.$refs.stripeButton.redirectToStripe();
		},

	},
};
</script>
<i18n>
{
	"it":{
		"CancelBooking": "Annulla l'appuntamento",
		"CompleteBooking": "Completa"
	}
}
</i18n>
